import getData from "@/__main__/get-data.mjs";
import { appURLs } from "@/app/constants.mjs";
import type { AbilitiesItems } from "@/game-deadlock/models/abilitiesItems.mjs";
import { AbilitiesItemsValidator } from "@/game-deadlock/models/abilitiesItems.mjs";
import type { AbilitiesItemsLocalized } from "@/game-deadlock/models/abiltiesItemsLocalized.mjs";
import { AbilitiesItemsLocalValidator } from "@/game-deadlock/models/abiltiesItemsLocalized.mjs";
import type { Heroes } from "@/game-deadlock/models/hero.mjs";
import { HeroesValidator } from "@/game-deadlock/models/hero.mjs";
import { devError } from "@/util/dev.mjs";

// Managed versions for static data
const ABILITIES_ITEMS_VERSION = "v1-3";
const ABILITIES_ITEMS_LOCALIZED_VERSION = "v1-5";

export default async function fetchData(): Promise<void> {
  await Promise.all([
    fetchHeroes(),
    fetchAbilitesItems(),
    fetchLocalAbilitiesItems(),
  ]);
}

function fetchHeroes(): Promise<Heroes> {
  try {
    return getData(
      `${appURLs.CDN_PLAIN}/blitz/deadlock/static/characters.json`,
      HeroesValidator,
      ["deadlock", "heroes"],
      {
        shouldFetchIfPathExists: false,
      },
    );
  } catch (e) {
    devError("Deadlock: Failed to fetch static data for heroes", e);
  }
}

function fetchAbilitesItems(): Promise<AbilitiesItems | undefined> {
  try {
    return getData(
      `${appURLs.CDN_PLAIN}/blitz/deadlock/static/abilities_items_${ABILITIES_ITEMS_VERSION}.json`,
      AbilitiesItemsValidator,
      ["deadlock", "abilitiesItems"],
      {
        expiryTime: Date.now() + 15 * 60 * 1000, // 15mins
      },
    );
  } catch (e) {
    devError("Deadlock: Failed to fetch static data for abilties and items", e);
  }
}

function fetchLocalAbilitiesItems(): Promise<
  AbilitiesItemsLocalized | undefined
> {
  const language = "en-us";
  try {
    return getData(
      `${appURLs.CDN_PLAIN}/blitz/deadlock/static/abilities_items_${language}_${ABILITIES_ITEMS_LOCALIZED_VERSION}.json`,
      AbilitiesItemsLocalValidator,
      ["deadlock", "abilitiesItemsLocal", language],
      {
        expiryTime: Date.now() + 15 * 60 * 1000, // 15mins
      },
    );
  } catch (e) {
    devError(
      "Deadlock: Failed to fetch static data for abilties and items localization",
      e,
    );
  }
}
