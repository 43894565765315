import type { DeepReadonly } from "ts-essentials";

import type { FromModel } from "@/__main__/data-model.mjs";
import createModel, { Mapped, Optional } from "@/__main__/data-model.mjs";

const Hero = {
  heroKey: Optional(String),
  heroName: Optional(String),
  id: Optional(Number),
  heroId: Optional(Number),
  imgUrl: Optional(String),
};

export const HeroesValidator = createModel([Hero]).transform(
  Mapped({
    key: String,
    value: Hero,
  }),
  (
    response: {
      heroKey?: string;
      heroName?: string;
      id?: number;
      heroId?: number;
      imgUrl?: string;
    }[],
  ) => {
    return response
      .filter((i) => i.id || i.heroId) // Characters that do not have ids are not yet implemented in the game
      .reduce(
        (acc, curr) => {
          acc[curr.id] = {
            ...curr,
            heroId: curr.id,
          };
          return acc;
        },
        {} as Partial<{
          [x: string]: Hero;
        }>,
      );
  },
);

export type Hero = {
  heroKey?: string;
  heroName?: string;
  id?: number;
  heroId?: number;
  imgUrl?: string;
};
export type Heroes = DeepReadonly<FromModel<typeof HeroesValidator>>;
